import { useState, useEffect } from 'react';

import { styled, Dialog, DialogContent, Button, Typography } from '@mui/material';

import DeleteChecklistIcon from '../../static/delete-checklist.svg';

import { messageBus } from '../../service/messageBus';

const DocumentDialog = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 24,
});

const StyledButton = styled(Button)({
  textTransform: 'initial',
  marginTop: 32,
  fontSize: 16,
  fontWeight: 600,
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',

  width: 170,
});

const CancelButton = styled(StyledButton)({
  border: '1px solid #D7D7DC',
  marginRight: 12,
});

const MainText = styled(Typography)({
  fontSize: 18,
  marginTop: 16,
});

const SubtitleText = styled(Typography)({
  textAlign: 'center',
  marginTop: 8,
});

const ButtonContainer = styled('div')({
  display: 'flex',
});

export const DeleteInvite = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [documentId, setDocumentId] = useState(null);

  useEffect(() => {
    const unsubscribeOpenDialog = messageBus.on('deleteDocument', ({ documentId }) => {
      setDocumentId(documentId);
      setDialogOpen(true);
    });

    return () => {
      unsubscribeOpenDialog();
    };
  });

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const confirmDeletion = () => {
    setDialogOpen(false);
    props.onConfirm(documentId);
  };

  return (
    <Dialog open={dialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DocumentDialog>
        <img src={DeleteChecklistIcon} alt="" />
        <MainText color="primary" variant="h6">
          Delete Invite?
        </MainText>
        <SubtitleText color="primary" variant="subtitle2">
          Invite will be deleted permanently from the system.
        </SubtitleText>
        <ButtonContainer>
          <CancelButton variant="contained" color="secondary" onClick={closeDialog}>
            Cancel
          </CancelButton>
          <StyledButton variant="contained" color="primary" onClick={confirmDeletion}>
            Delete
          </StyledButton>
        </ButtonContainer>
      </DocumentDialog>
    </Dialog>
  );
};
