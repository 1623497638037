import { logoutAction } from '../store/actions/logout';
import { store } from '../store/store';

const successStatuses = [200, 201, 204];

export const httpRequest = async (requestInfo, requestParams = {}) => {
  const requestOptions = {
    method: requestInfo.method,
    headers: {},
  };

  if (['POST', 'PATCH', 'PUT'].includes(requestOptions.method) && !requestParams.form) {
    requestOptions.headers['Content-Type'] = 'application/json';
  }

  if (!requestParams.sendUnauthorized) {
    const token = store.getState().token;

    if (!token) {
      console.error(`Cannot send HTTP request, there is no auth token available`);

      return;
    }

    requestOptions.headers.Authorization = token;
  }

  if (requestParams.data) {
    requestOptions.body = JSON.stringify(requestParams.data);
  }

  if (requestParams.form) {
    requestOptions.body = requestParams.form;
  }

  const requestUrl = `${requestInfo.url}${requestInfo.route}${requestParams.param ? '/' + requestParams.param : ''}`;

  const res = await fetch(requestUrl, requestOptions);

  if (res.status === 401) {
    store.dispatch(logoutAction());

    const msal = store.getState().msal;
    msal.loginRedirect({ scopes: [process.env.REACT_APP_SSO_SCOPE] });
  }

  if (!successStatuses.includes(res.status)) {
    throw new Error(res.statusText);
  }

  switch (requestParams.parseResponse) {
    case false: {
      return;
    }
    case 'json': {
      return res.json();
    }
    case 'blob': {
      return res.blob();
    }
    default: {
      return res.json();
    }
  }
};
