import { styled, TableCell, TableRow } from '@mui/material';

import { ContentCopy, DeleteOutline } from '@mui/icons-material';

import { messageBus } from '../../service/messageBus';

const DoubleToneTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)({
  fontSize: 16,
  padding: '15px 21px',
});

export const InviteCard = (props) => {
  const handleDocumentDelete = async (id) => {
    messageBus.send('deleteDocument', { documentId: id });
  };

  const handleCopyLink = (inviteCode) => {
    console.dir(process.env);
    const invite = `${process.env.REACT_APP_INVITE_BASE_URL}?invite=adgm&code=${inviteCode}`;

    navigator.clipboard.writeText(invite);
  };

  return (
    <DoubleToneTableRow>
      <StyledTableCell>{props.document.name}</StyledTableCell>
      <StyledTableCell>{props.document.startDate}</StyledTableCell>
      <StyledTableCell>{props.document.endDate}</StyledTableCell>
      <StyledTableCell>{props.document.createdAt}</StyledTableCell>
      <StyledTableCell>
        <div className="button-row">
          <ContentCopy className="colored-icon" onClick={() => handleCopyLink(props.document.id)} alt="" />
          <DeleteOutline className="colored-icon delete-icon" onClick={() => handleDocumentDelete(props.document.id)} />
        </div>
      </StyledTableCell>
    </DoubleToneTableRow>
  );
};
