import './error-form.scss';

import { useState, useEffect } from 'react';

import { Dialog, DialogContent } from '@mui/material';
import { Close } from '@mui/icons-material';
import { messageBus } from '../../service/messageBus';

export const ErrorForm = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const unsubscribeOpenDialog = messageBus.on('openErrorFormDialog', async (data) => {
      const errorMessage = data.errorMessage;

      setErrorMessage(errorMessage);
      setDialogOpen(true);
    });

    return () => {
      unsubscribeOpenDialog();
    };
  });

  return (
    <Dialog id="errorForm" open={dialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <div className="dialog-header">
          <p className="header-text">Error</p>
          <Close className="close-icon" onClick={() => setDialogOpen(false)} />
        </div>
        <p className="scan-description-text">{errorMessage}</p>
      </DialogContent>
    </Dialog>
  );
};
