import './landing.scss';

import { useState, useEffect } from 'react';

import { styled, Dialog, DialogContent, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { messageBus } from '../../service/messageBus';

const LinkButton = styled(Button)({
  float: 'right',
  fontWeight: 800,
});

export const Landing = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inviteLink, setInviteLink] = useState('');

  useEffect(() => {
    const unsubscribeOpenDialog = messageBus.on('openLandingDialog', async (data) => {
      const invite = `${process.env.REACT_APP_INVITE_BASE_URL}?invite=adgm&code=${data.inviteCode}`;

      setInviteLink(invite);
      setDialogOpen(true);
    });

    return () => {
      unsubscribeOpenDialog();
    };
  });

  const handleCopyLink = (invite) => {
    navigator.clipboard.writeText(invite);
  };

  return (
    <Dialog id="landing" open={dialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <div className="dialog-header">
          <p className="header-text">Invite link</p>
          <Close className="close-icon" onClick={() => setDialogOpen(false)} />
        </div>
        <p className="scan-description-text">{inviteLink}</p>
        <div className="button-container">
          <LinkButton variant="contained" onClick={() => handleCopyLink(inviteLink)}>
            Copy
          </LinkButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
