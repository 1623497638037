const API_URL = process.env.REACT_APP_URL;

export const LOGIN = { method: 'POST', route: '/login', url: API_URL };

export const USER_DETAILS = { method: 'GET', route: '/user-details', url: API_URL };
export const CREATE_INVITE_CODE = { method: 'POST', route: '/create-invite-code', url: API_URL };
export const GET_INVITES_LIST = { method: 'GET', route: '/invite-code-list', url: API_URL };
export const GET_ROOMS_LIST = { method: 'GET', route: '/room', url: API_URL };
export const DELETE_INVITE = { method: 'DELETE', route: '/invite-code', url: API_URL };
export const UPDATE_ROOM = { method: 'PATCH', route: '/room', url: API_URL };
export const GET_SETTINGS_LIST = { method: 'GET', route: '/setting', url: API_URL };
export const UPDATE_SETTING = { method: 'PATCH', route: '/setting', url: API_URL };
