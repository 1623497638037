import './inviteList.scss';

import { useEffect, useState } from 'react';

import { format, addDays } from 'date-fns';

import { styled, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { InviteCard } from './InviteCard';
import { EmptyList } from '../Shared/EmptyList';
import { DeleteInvite } from './DeleteInvite';

import { ReskillInput, ReskillLabel } from '../Shared/Inputs';
import { LoadingOverlay } from '../Shared/Loading';

import { httpRequest } from '../../service/request';
import { messageBus } from '../../service/messageBus';

import { DELETE_INVITE, CREATE_INVITE_CODE, GET_INVITES_LIST } from '../../routes';

const HeaderTableCell = styled(TableCell)({
  fontSize: 14,
  color: '#6C6C6C',
  padding: 21,
});

const SaveButton = styled(Button)({
  marginTop: 24,
  fontWeight: 800,
});

const CreateInput = styled(ReskillInput)({
  width: 300,
  marginRight: 40,
});

const compareDates = (itemA, itemB) => {
  const dateA = new Date(itemA.createdAt);
  const dateB = new Date(itemB.createdAt);

  return dateB - dateA;
};

const formatDate = (item) => {
  item.createdAt = format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm');
  item.startDate = format(new Date(item.startDate), 'yyyy-MM-dd HH:mm');
  item.endDate = format(new Date(item.endDate), 'yyyy-MM-dd HH:mm');
};

const getDefaultStartDate = () => new Date();
const getDefaultEndDate = () => addDays(new Date(), 1);

export const InviteList = (props) => {
  const [guestName, setGuestName] = useState('');

  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());

  const [loading, setLoading] = useState(false);

  const [invites, setInvites] = useState([]);

  useEffect(() => {
    loadInvites();
  }, []);

  const loadInvites = async () => {
    try {
      setLoading(true);

      const { invites } = await httpRequest(GET_INVITES_LIST);

      invites.sort(compareDates).forEach(formatDate);

      setInvites(invites);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteConfirmation = async (inviteId) => {
    await httpRequest(DELETE_INVITE, { param: inviteId, parseResponse: false });

    loadInvites();
  };

  const clearInformationFields = () => {
    setGuestName('');
    setStartDate(getDefaultStartDate());
    setEndDate(getDefaultEndDate());
  }

  const validateInputParams = (guestName, startDate, endDate) => {
    if (!validateQuestName(guestName)) {
      setGuestName('');

      messageBus.send('openErrorFormDialog', { errorMessage: 'Name must be from 3 to 50 symbols without special characters excludes dot, apostrophe and hyphen.' });

      return false;
    }

    if (new Date(startDate) >= new Date(endDate)) {
      setStartDate(getDefaultStartDate());
      setEndDate(getDefaultEndDate());

      messageBus.send('openErrorFormDialog', { errorMessage: 'Start date can not be greater than end date.' });

      return false;
    }

    
    const currentDate = new Date();
  
    if (new Date(endDate) < currentDate) {
      setEndDate(getDefaultEndDate());

      messageBus.send('openErrorFormDialog', { errorMessage: 'End date can not be lesser than current date.' });

      return false;
    }
  
    currentDate.setDate(currentDate.getDate() - 1);
  
    if (new Date(startDate) < currentDate) {
      setStartDate(getDefaultStartDate());

      messageBus.send('openErrorFormDialog', { errorMessage: 'Start date can not be lesser than yesterday.' });

      return false;
    }

    return true;
  }

  const handleSave = async () => {
    const data = {
      guestName,
      startDate,
      endDate,
    };

    if (validateInputParams(guestName, startDate, endDate)) {
      const { inviteCode } = await httpRequest(CREATE_INVITE_CODE, { data });

      clearInformationFields();
  
      messageBus.send('openLandingDialog', { inviteCode });

      loadInvites();
    }
  };

  const validateQuestName = (name) => {
    const regExp = new RegExp('^[a-zA-Z0-9-.`\' ]{3,50}$');

    return regExp.test(name);
  }

  return (
    <div id="inviteList-module">
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div>
          <DeleteInvite onConfirm={handleDeleteConfirmation} />

          <div className="page-header">
            <div>
              <Typography variant="h4" color="primary" className="page-header">
                Invites
              </Typography>
            </div>
            <div className="button-section">
              <div>
                <ReskillLabel color="primary" variant="subtitle2">
                  Guest name
                </ReskillLabel>
                <CreateInput
                  id="standard-basic"
                  variant="outlined"
                  margin="none"
                  value={guestName}
                  inputProps={{ maxLength: '100' }}
                  onChange={(e) => setGuestName(e.target.value)}
                  fullWidth
                />
              </div>
              <div>
                <ReskillLabel color="primary" variant="subtitle2">
                  Invite start date
                </ReskillLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    id="standard-select"
                    className="datepicker"
                    margin="none"
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                    fullWidth
                    select
                  />
                </LocalizationProvider>
              </div>
              <div>
                <ReskillLabel color="primary" variant="subtitle2">
                  Invite end date
                </ReskillLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    id="standard-select"
                    className="datepicker"
                    margin="none"
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                    fullWidth
                    select
                  />
                </LocalizationProvider>
              </div>
              <SaveButton variant="contained" color="primary" onClick={handleSave}>
                Create invite
              </SaveButton>
            </div>
          </div>

          {invites.length === 0 ? (
            <EmptyList listName = {'Invites'}/>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell>Name</HeaderTableCell>
                    <HeaderTableCell>Start Date</HeaderTableCell>
                    <HeaderTableCell>End Date</HeaderTableCell>
                    <HeaderTableCell>Created At</HeaderTableCell>
                    <HeaderTableCell></HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invites.map((document, i) => (
                    <InviteCard key={i} document={document} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <br />
        </div>
      )}
    </div>
  );
};
