import './header.scss';

export const Header = () => {
  return (
    <div id="header-module">
      <img className="reskill-logo" src="admin/adgm-logo.svg"></img>
      <div className='header-container'>
        <p className="app-logo-text">ADGM</p>
        <p className="app-logo-second-text">Arbitration Centre</p>
      </div>
    </div>
  );
};
