const listeners = {
  sendImage: new Set(),
  clearImage: new Set(),
  openQrDialog: new Set(),
  openDocumentAiDialog: new Set(),
  openLandingDialog: new Set(),
  checklistCreated: new Set(),
  deleteChecklist: new Set(),
  resetChecklist: new Set(),
  deleteDocument: new Set(),
  fieldError: new Set(),
  openErrorFormDialog: new Set(),
};

Object.freeze(listeners);

class MessageBus {
  on(message, handler) {
    const listenersSet = listeners[message];

    if (!listenersSet) {
      console.log(`Subscription to message ${message} is not allowed`);

      return;
    }

    listenersSet.add(handler);

    return () => listenersSet.delete(handler);
  }

  send(eventName, data) {
    const listenersSet = listeners[eventName];

    if (!listenersSet) {
      console.error(`Cannot handle event "${eventName}"`);

      return;
    }

    listenersSet.forEach((listener) => {
      listener(data);
    });
  }
}

export const messageBus = new MessageBus();
