import { createStore } from 'redux';

import { rootReducer } from './reducers/root';

import { initialState } from './initialState';

export const store = createStore(
  rootReducer,
  initialState,
);
