import './loading-overlay.scss';

import { CircularProgress } from '@mui/material';

export const LoadingOverlay = (props) => {
  return (
    <div id="loading-overlay-component">
      <CircularProgress />
    </div>
  );
};
