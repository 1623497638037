import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useSelector, useDispatch } from 'react-redux';
import { PublicClientApplication, LogLevel, EventType } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';

import { ThemeProvider, createTheme } from '@mui/material';

import { loginAction } from './store/actions/login';

import { Main } from './components/Main/Main';
import { EmptyPage } from './components/EmptyPage/EmptyPage';
import { setMsalAction } from './store/actions/setMsal';

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
    h4: {
      fontSize: 36,
      fontWeight: 600,
      marginLeft: -1,
      color: '#323232!important',
    },
    h5: {
      fontSize: 24,
      fontWeight: 600,
      marginLeft: -1,
      color: '#323232!important',
    },
    body1: {
      color: '#323232!important',
    },
    subtitle1: {
      fontSize: 18,
      color: '#6c6c6c!important',
    },
    subtitle2: {
      fontSize: 14,
      color: '#6c6c6c!important',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    authority: process.env.REACT_APP_SSO_AUTHORITY,
    redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
            return;
        }
      },
    },
  },
};

const myMSALObj = new PublicClientApplication(msalConfig);

const App = () => {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  dispatch(setMsalAction(myMSALObj));

  const callRefreshTimer = (msal, time, username) => {
    return setTimeout(async () => {
      const currentAccount = msal.getAccountByUsername(username);

      const response = await msal.acquireTokenSilent({ scopes: [process.env.REACT_APP_SSO_SCOPE], account:currentAccount});

      dispatch(loginAction(response.accessToken));

      callRefreshTimer(msal, new Date(response.expiresOn).getTime() - Date.now() - 5 * 60 * 1000, response.account.username);
    }, time);
  };

  const initializeSignIn = async () => {
    return myMSALObj.loginRedirect({ scopes: [process.env.REACT_APP_SSO_SCOPE] });
  };

  const checkLogin = () => {
    const accounts = myMSALObj.getAllAccounts();

    if (!accounts.length) {
      initializeSignIn();
    } else {
      const storage = JSON.parse(localStorage.getItem(`msal.token.keys.${msalConfig.auth.clientId}`));

      const { accessToken: accessTokenKey } = storage;

      const { secret: accessToken } = JSON.parse(localStorage.getItem(accessTokenKey))

      dispatch(loginAction(accessToken));
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    myMSALObj.handleRedirectPromise().then(authResult => {
      if (!authResult) {
        checkLogin();
      } else {
        dispatch(loginAction(authResult.accessToken));

        const accounts = myMSALObj.getAllAccounts();

        if (!accounts.length) return;

        const timer = callRefreshTimer(myMSALObj, new Date(activeAccount.idTokenClaims.exp).getTime() - Date.now() - 5 * 60 * 1000, activeAccount.username);
  
        return () => clearTimeout(timer);
      }
    }).catch(err=>{
      console.error(err);
    });
  }, [myMSALObj]);

  createBrowserHistory({
    basename: process.env.PUBLIC_URL,
  });

  const accounts = myMSALObj.getAllAccounts();

  if (accounts.length > 0) {
    myMSALObj.setActiveAccount(accounts[0]);
  }

  const activeAccount = myMSALObj.getActiveAccount();

  useEffect(() => {
    if (!activeAccount) return;

    const timer = callRefreshTimer(myMSALObj, new Date(activeAccount.idTokenClaims.exp).getTime() - Date.now() - 5 * 60 * 1000, activeAccount.username);

    return () => clearTimeout(timer);
  }, []);

  myMSALObj.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;

      myMSALObj.setActiveAccount(account);
    }
  }, error=>{
    console.log('error', error);
  });

  const checkPermissions = (token) => {
    try {
      if (!token) return <div></div>;

      const decoded = jwtDecode(token);

      if (decoded.roles.includes(process.env.REACT_APP_ADMIN_ROLE)) return <Main />;

      return <EmptyPage />
    } catch (error) {
      console.error('error', error);
      return '';
    }
  }

  return (
    <BrowserRouter basename="/admin">
      <ThemeProvider theme={theme}>
        <div className="App">{checkPermissions(token)}</div>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
