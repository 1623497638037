export const tokenReducer = (state = '', action) => {
  switch (action.type) {
    case 'LOGIN':
      return action.value;
    case 'LOGOUT':
      localStorage.clear();

      return null;
    default:
      return state;
  }
};
