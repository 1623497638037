import { styled, TextField } from '@mui/material';

import { Typography } from '@mui/material';

export const ReskillInput = styled(TextField)({
  '.MuiOutlinedInput-root': {
    backgroundColor: 'white',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#d7d7dc',
  },
  '.Mui-error': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#c00000',
    },
  },
  '.MuiFormHelperText-root.Mui-error': {
    color: '#c00000',
  },
  '.MuiFormHelperText-contained': {
    fontSize: 14,
    marginTop: 8,
    marginLeft: 0,
  },
  '.MuiOutlinedInput-input': {
    height: 24,
    padding: '10px 14px',
    color: 'black',
  },
  '.MuiOutlinedInput-input::placeholder': {
    color: '#6c6c6c',
  },
});

export const ReskillLabel = styled(Typography)({
  marginBottom: 4,
});
