import './main.scss';

import { useSelector } from 'react-redux';
import { Link as RouterLink, Switch, Route } from 'react-router-dom';

import { MailOutline } from '@mui/icons-material';

import { styled, Container, CssBaseline, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { InviteList } from '../Invites/InviteList';
import { RoomList } from '../Rooms/RoomList';
import { SettingList } from '../Settings/SettingList';
import { Landing } from '../Landing/Landing';
import { ErrorForm } from '../ErrorForm/ErrorForm';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';

// Also change .full-screen-route in remoteAssistance.scss !!!!
const drawerWidth = 265;

const StyledDrawer = styled(Drawer)({
  position: 'relative',
  whiteSpace: 'nowrap',
  width: drawerWidth,
  height: '100vh',

  '.MuiDrawer-paper': {
    width: drawerWidth,
  },
});

const StyledContainer = styled(Container)({
  paddingTop: '39px!important',
  paddingRight: '67px!important',
  paddingLeft: '47px!important',
  maxWidth: 'initial!important',
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
});

export const Main = () => {
  const token = useSelector((state) => state.token);

  return (
    <div id="main-module">
      <CssBaseline />
      <StyledDrawer variant="permanent">
        <Header />
        <Divider />
        <List>
          <ListItem className="push-bottom" component={RouterLink} to="/invites" button>
            <ListItemIcon>
              <MailOutline />
            </ListItemIcon>
            <ListItemText primary="Invites" />
          </ListItem>
          <ListItem className="push-bottom" component={RouterLink} to="/rooms" button>
            <ListItemIcon>
              <MailOutline />
            </ListItemIcon>
            <ListItemText primary="Rooms" />
          </ListItem>
          <ListItem className="push-bottom" component={RouterLink} to="/settings" button>
            <ListItemIcon>
              <MailOutline />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
        <Divider />
        <Footer />
      </StyledDrawer>
      <StyledContainer id="content-container">
        <Landing />
        <ErrorForm />
        <Switch>
          <Route path="/invites">
            <InviteList token={token} />
          </Route>
          <Route path="/rooms">
            <RoomList token={token} />
          </Route>
          <Route path="/settings">
            <SettingList token={token} />
          </Route>
        </Switch>
      </StyledContainer>
    </div>
  );
};
