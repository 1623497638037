import { Input, styled, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { Save } from '@mui/icons-material';

import { httpRequest } from '../../service/request';
import { UPDATE_SETTING } from '../../routes';
import { messageBus } from '../../service/messageBus';

const DoubleToneTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)({
  fontSize: 16,
  padding: '15px 21px',
});

export const SettingCard = (props) => {

  const [value, setValue] = useState(props.document.value);
  const [baseValue, setBaseValue] = useState(props.document.value);

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const handleSave = async (key, savedValue) => {
    try {
      await httpRequest(UPDATE_SETTING, { param: key, data: { value: savedValue }, parseResponse: false});

      setBaseValue(savedValue)
    } catch (err) {
      messageBus.send('openErrorFormDialog', { errorMessage: err.message });

      setValue(baseValue);
    }
  }

  return (
    <DoubleToneTableRow>
      <StyledTableCell>{props.document.title}</StyledTableCell>
      <StyledTableCell>{props.document.defaultValue}</StyledTableCell>
      <StyledTableCell>
        <Input
          value={value ?? ''}
          type="string"
          onChange={handleChange}
        />
      </StyledTableCell>
      <StyledTableCell>
        <div className="button-row">
          <Save className="colored-icon" onClick={() => handleSave(props.document.key, value)} alt="" />
        </div>
      </StyledTableCell>
    </DoubleToneTableRow>
  );
};
