import './footer.scss';

import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Logout from '../../../static/icons/logout.svg';

import { httpRequest } from '../../../service/request';
import { logoutAction } from '../../../store/actions/logout';

import { USER_DETAILS } from '../../../routes';

export const Footer = () => {
  const dispatch = useDispatch();

  const msal = useSelector((state) => state.msal);

  const [user, setUser] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const user = await httpRequest(USER_DETAILS);

    setUser(user);
  };

  const logout = async () => {
    try {
      dispatch(logoutAction());
      msal.logoutRedirect();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div id="footer-module">
      <div>
        <p className="user-name">{user.name}</p>
        <p className="user-email">{user.login}</p>
      </div>
      <img src={Logout} alt="" onClick={logout} className="logout-icon" />
    </div>
  );
};
