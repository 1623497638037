import { setMsalAction } from '../actions/setMsal';

export const msalReducer = (state = '', action) => {
  switch (action.type) {
    case setMsalAction().type:
      return action.value;
    default:
      return state;
  }
};
