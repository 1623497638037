import { Input, styled, TableCell, TableRow } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { Save } from '@mui/icons-material';

import { httpRequest } from '../../service/request';
import { UPDATE_ROOM } from '../../routes';
import { messageBus } from '../../service/messageBus';

const DoubleToneTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)({
  fontSize: 16,
  padding: '15px 21px',
});

export const RoomCard = (props) => {
  const [value, setValue] = useState(props.document.delayTime);

  const handleChange = (event) => {
    setValue(Number(event.target.value) < 0 ? 0 : Number(event.target.value) > 60 ? 60 : event.target.value);
  }

  const handleSave = async (id, value) => {
    try {
      await httpRequest(UPDATE_ROOM, { param: id, data: { delayTime: Number(value) }, parseResponse: false});
    } catch (err) {
      messageBus.send('openErrorFormDialog', { errorMessage: err.message });
    }
  }

  return (
    <DoubleToneTableRow>
      <StyledTableCell>{props.document.id}</StyledTableCell>
      <StyledTableCell>{props.document.maxAllowed}</StyledTableCell>
      <StyledTableCell>{format(new Date(props.document.createdAt), 'yyyy-MM-dd HH:mm')}</StyledTableCell>
      <StyledTableCell>
        <Input
          value={value ?? ''}
          type="number"
          inputProps={{ min: 0, max: 60 }}
          onChange={handleChange}
        />
      </StyledTableCell>
      <StyledTableCell>
        <div className="button-row">
          <Save className="colored-icon" onClick={() => handleSave(props.document.id, value)} alt=""/>
        </div>
      </StyledTableCell>
    </DoubleToneTableRow>
  );
};
