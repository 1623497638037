import './settingList.scss';

import { useEffect, useState } from 'react';

import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

import { LoadingOverlay } from '../Shared/Loading';

import { httpRequest } from '../../service/request';

import { GET_SETTINGS_LIST } from '../../routes';

import { EmptyList } from '../Shared/EmptyList';
import { SettingCard } from './SettingCard';

const HeaderTableCell = styled(TableCell)({
  fontSize: 14,
  color: '#6C6C6C',
  padding: 21,
});


export const SettingList = (props) => {
  const [loading, setLoading] = useState(false);

  const [settings, setSettings] = useState([]);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      setLoading(true);

      const response = await httpRequest(GET_SETTINGS_LIST);

      setSettings(response);
  
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="settingList-module">
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div>
          <div className="page-header">
            <div>
              <Typography variant="h4" color="primary" className="page-header">
                Settings
              </Typography>
            </div>
          </div>

          {settings.length === 0 ? (
            <EmptyList listName = {'Settings'}/>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell>Zoom</HeaderTableCell>
                    <HeaderTableCell>Recommended value (in degrees)</HeaderTableCell>
                    <HeaderTableCell>Value (1 - 110)</HeaderTableCell>
                    <HeaderTableCell></HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {settings.map((document, i) => (
                    <SettingCard key={i} document={document} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <br />
        </div>
      )}
    </div>
  );
};
