import './empty-documents.scss';

import { styled, Typography } from '@mui/material';

import SearchIcon from '../../static/empty-checklists.svg';

const EmptyChecklistHeader = styled(Typography)({
  marginTop: 12,
});

export const EmptyList = (props) => {
  return (
    <div className="empty-documents">
      <img src={SearchIcon} alt="" />
      <EmptyChecklistHeader variant="body1" color="primary">
        {`No ${props.listName} created`}
      </EmptyChecklistHeader>
    </div>
  );
};
