import './roomList.scss';

import { useEffect, useState } from 'react';

import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

import { LoadingOverlay } from '../Shared/Loading';

import { httpRequest } from '../../service/request';

import { GET_ROOMS_LIST } from '../../routes';

import { EmptyList } from '../Shared/EmptyList';
import { RoomCard } from './RoomCard';

const HeaderTableCell = styled(TableCell)({
  fontSize: 14,
  color: '#6C6C6C',
  padding: 21,
});


export const RoomList = (props) => {
  const [loading, setLoading] = useState(false);

  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    loadRooms();
  }, []);

  const loadRooms = async () => {
    try {
      setLoading(true);

      const response = await httpRequest(GET_ROOMS_LIST);

      setRooms(response);
  
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="roomList-module">
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div>
          <div className="page-header">
            <div>
              <Typography variant="h4" color="primary" className="page-header">
                Rooms
              </Typography>
            </div>
          </div>

          {rooms.length === 0 ? (
            <EmptyList listName = {'Rooms'}/>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell>Room name</HeaderTableCell>
                    <HeaderTableCell>Max Allowed</HeaderTableCell>
                    <HeaderTableCell>Created At</HeaderTableCell>
                    <HeaderTableCell>Delay Time (max: 60 sec)</HeaderTableCell>
                    <HeaderTableCell></HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rooms.filter((room) => room.id !== "Lobby").map((document, i) => (
                    <RoomCard key={i} document={document} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <br />
        </div>
      )}
    </div>
  );
};
