import './emptyPage.scss';

import { useDispatch, useSelector } from 'react-redux';

import { styled, Typography } from '@mui/material';

import Logout from '../../static/icons/logout.svg';
import { logoutAction } from '../../store/actions/logout';

const EmptyStateContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const EmptyPageHeader = styled(Typography)({
  fontWeight: 'bold',
});

const EmptyPageSubheader = styled(Typography)({
  marginTop: 4,
});

export const EmptyPage = () => {
  const dispatch = useDispatch();

  const msal = useSelector((state) => state.msal);

  const logout = async () => {
    try {
      dispatch(logoutAction());
      msal.logoutRedirect();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <EmptyStateContainer>
      <EmptyPageHeader variant="body1" color="primary">
      {`Permission denied`}
      </EmptyPageHeader>
      <EmptyPageSubheader variant="subtitle2" color="primary">
        {`You don't have the required Azure AD role to access this page. Please contact ADGM Azure admin to obtain the needed access.`}
      </EmptyPageSubheader>
      <div className='logout-title' onClick={logout}>
        <Typography className="logout-text">Choose another account</Typography>
        <img src={Logout} alt="" className="logout-icon" />
      </div>
    </EmptyStateContainer>
  );
};
